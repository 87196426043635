const game = {
    101: "百家樂",
    102: "射龍門",
    103: "火蟾蜍",
    104: "龍虎",
    105: "炸金花",
    106: "百人牛牛",
    107: "二八槓",
    108: "森林大轉輪",
    109: "奔馳寶馬",
    110: "骰寶",
    111: "左輪百家",
    112: "骰子百家",
    113: "推筒子",
    114: "魚蝦蟹",
    115: "爬梯子",
    116: "眯牌百家",
    117: "移牌百家(多管)",
    118: "移牌百家(單管)",
    119: "五星宏輝",
    120: "紅黑大戰",
    121: "色碟",
    122: "番攤",
    123: "視訊百家",
    124: "印度炸金花",
    125: "免傭安達巴哈",
    126: "安達巴哈",
    301: "對戰炸金花",
    302: "搶莊牛牛",
    303: "極速炸金花",
    304: "單挑牛牛",
    305: "通比牛牛",
    306: "搶莊二八槓",
    307: "搶莊推筒子",
    308: "三公",
    309: "牌九",
    310: "搶莊炸金花",
    311: "越南撲克",
    312: "博登",
    313: "21點",
    314: "對戰猜大小",
    501: "龍王捕魚",
    502: "海王捕魚",
    10001: "火鳳凰",
    10002: "埃及豔后",
    10003: "百變熊貓",
    10004: "贏多多",
    10005: "齊天大聖",
    10006: "五神獸",
    10007: "三倍猴子",
    10008: "多彩多福",
    10009: "夜上海",
    10010: "眾神",
    10012: "海洋派對",
    10013: "后羿射日",
    10014: "麻將來了",
    10015: "蔬果精靈",
    10016: "新火鳳凰",
    10017: "魔龍傳奇",
    10018: "山海誌異",
    10019: "野蠻世界",
    10020: "王者之劍",
    10021: "聚寶盆",
    10022: "埃及之眼",
    10023: "海盜寶藏",
    10024: "雷神",
    10025: "阿拉丁神燈",
    10026: "戰神呂布",
    10027: "麻將來了2",
    10028: "忍者武士",
    10029: "七龍珠",
    10030: "海賊王",
    10031: '大富翁',
    10032: '海賊王2',
    10033: '戰神賽特',
    10034: '神龍傳奇',
    10035: '強棒',
    10036: '養雞場',
    10039: '大老爺',
    20002: "五福臨門",
};

const bacc = {
    1: "閒",
    2: "莊",
    3: "和",
    4: "閒對",
    5: "莊對",
}

let diceRoadType = {
    0: "預設",
    1: "大",
    2: "小",
    3: "豹子",
};

let climbLadder = {
    1: "開始左邊",
    2: "開始右邊",
    3: "終點左邊",
    4: "終點右邊",
    5: "梯子數量3",
    6: "梯子數量4",
    7: "終點左邊且梯子數量3",
    8: "終點右邊且梯子數量3",
    9: "終點左邊且梯子數量4",
    10: "終點右邊且梯子數量4"
};

export default {
    ...game,
    init: "取得遊戲設定",
    switchType: {
        0: "預設",
        1: "帶牌",
        2: "殺數",
    },
    gameModule: {
        title: "遊戲類型",
        0: "沒有下注",
        1: "百人棋牌",
        2: "對戰棋牌",
        3: "街機-老虎機",
        4: "街機-捕魚機",
    },
    title: "遊戲",
    win: "贏",
    lose: "輸",
    pay: "賠",
    betArea: "下注區",
    areaName: "押注區",
    moveCard: "移牌區",
    backCard: "備牌區",
    orderOfOpeningCard: "開牌順序",
    licensingOrder: "發牌順序",
    state: {
        title: "遊戲狀態",
        sleep: "遊戲結束",
        notEnd: "遊戲尚未結束"
    },
    banker: "莊",
    player: "閒",
    tie: "和",
    rightStart: "右邊開始",
    rightEnd: "右邊結束",
    leftStart: "左邊開始",
    leftEnd: "左邊結束",
    numberOfLadders: "{n}個梯子",
    seat: "座位",
    area: {
        //百家樂
        101: bacc,
        //射龍門
        102: {
            1: "撞柱",
            2: "射中龍門",
            3: "沒中",
            4: "紅",
            5: "黑",
            6: "大",
            7: "小",
            8: "單",
            9: "雙",
        },
        //火蟾蜍
        103: climbLadder,
        //龍虎
        104: {
            1: "龍",
            2: "虎",
            3: "和",
        },
        //炸金花
        105: {
            1: "莊",
            2: "天",
            3: "地",
            4: "玄",
            5: "黃",
            winType: {
                1: "豹子",
                2: "同花順",
                3: "金花",
                4: "順子",
                5: "對子",
                6: "高牌",
                7: "地龍",
            },
            isLose: "莊家通殺"
        },
        //百人牛牛
        106: {
            1: "莊",
            2: "天",
            3: "地",
            4: "玄",
            5: "黃",
            winType: {
                0: "五小牛",
                1: "四炸",
                2: "五花牛",
                3: "四花牛",
                4: "牛牛",
                5: "牛九",
                6: "牛八",
                7: "牛七",
                8: "牛六",
                9: "牛五",
                10: "牛四",
                11: "牛三",
                12: "牛二",
                13: "牛一",
                14: "無牛",
            },
            rateRule: {
                1: "沒牛到牛六",
                2: "牛七到牛九",
                3: "牛牛到五小牛",
                4: "抽水",
            },
            isLose: "莊家通殺"
        },
        //二八槓
        107: {
            2: "上門-贏",
            3: "中門-贏",
            4: "下門-贏",
            5: "上門-輸",
            6: "中門-輸",
            7: "下門-輸",
            rateRule: {
                1: "點數",
                2: "對子",
                3: "白板",
                4: "贏抽水",
                5: "輸抽水",
            },
            winType: {
                1: "鱉十",
                2: "{n}點",
                3: "二八槓",
                4: "對子",
            },
            block: {
                1: "莊家",
                2: "上門",
                3: "中門",
                4: "下門",
            },
        },
        //森林大轉輪
        108: {
            1: "獅子紅",
            2: "獅子綠",
            3: "獅子黃",
            4: "熊貓紅",
            5: "熊貓綠",
            6: "熊貓黃",
            7: "猴子紅",
            8: "猴子綠",
            9: "猴子黃",
            10: "兔子紅",
            11: "兔子綠",
            12: "兔子黃",
            nowSpecialRule: {
                1: "大三元",
                2: "大四喜",
                3: "送燈",
                4: "霹靂閃電",
            }
        },
        //奔馳寶馬
        109: {
            1: "法拉利",
            2: "藍寶堅尼",
            3: "瑪莎拉蒂",
            4: "保時捷",
            5: "Lexus",
            6: "福斯大眾",
            7: "奔馳賓士",
            8: "寶馬BMW",
        },
        //骰寶
        110: {
            1: "豹子1",
            2: "豹子2",
            3: "豹子3",
            4: "豹子4",
            5: "豹子5",
            6: "豹子6",
            7: "任意豹子",
            8: "總數4",
            9: "總數5",
            10: "總數6",
            11: "總數7",
            12: "總數8",
            13: "總數9",
            14: "總數10",
            15: "總數11",
            16: "總數12",
            17: "總數13",
            18: "總數14",
            19: "總數15",
            20: "總數16",
            21: "總數17",
            22: "骰子1",
            23: "骰子2",
            24: "骰子3",
            25: "骰子4",
            26: "骰子5",
            27: "骰子6",
            28: "大",
            29: "小",
            30: "單",
            31: "雙",
            roadType: diceRoadType
        },
        //左輪百家
        111: bacc,
        //骰子百家
        112: bacc,
        //推筒子
        113: {
            2: "出-贏",
            3: "川-贏",
            4: "尾-贏",
            5: "出-輸",
            6: "川-輸",
            7: "尾-輸",
            rateRule: {
                1: "點數",
                2: "對子",
                3: "白板",
                4: "贏抽水",
                5: "輸抽水",
            },
            block: {
                1: "莊",
                2: "出",
                3: "川",
                4: "尾",
            },
            winType: {
                1: "鱉十",
                2: "{n}點",
                3: "對子",
            },
        },
        //魚蝦蟹
        114: {
            1: "1點(魚)-圍骰",
            2: "2點(蝦)-圍骰",
            3: "3點(葫盧)-圍骰",
            4: "4點(銅錢)-圍骰",
            5: "5點(螃蟹)-圍骰",
            6: "6點(雞)-圍骰",
            7: "全圍",
            8: "指定三色-紅",
            9: "指定三色-綠",
            10: "指定三色-藍",
            11: "任意三色",
            12: "指定雙色-紅",
            13: "指定雙色-綠",
            14: "指定雙色-藍",
            15: "指定單色-紅",
            16: "指定單色-綠",
            17: "指定單色-藍",
            18: "總點-4點",
            19: "總點-5點",
            20: "總點-6點",
            21: "總點-7點",
            22: "總點-8點",
            23: "總點-9點",
            24: "總點-10點",
            25: "總點-11點",
            26: "總點-12點",
            27: "總點-13點",
            28: "總點-14點",
            29: "總點-15點",
            30: "總點-16點",
            31: "總點-17點",
            32: "三軍-1點",
            33: "三軍-2點",
            34: "三軍-3點",
            35: "三軍-4點",
            36: "三軍-5點",
            37: "三軍-6點",
            38: "大",
            39: "小",
            40: "單",
            41: "雙",
            roadType: diceRoadType
        },
        //爬梯子
        115: climbLadder,
        //眯牌百家
        116: bacc,
        //移牌百家(多管)
        117: bacc,
        //移牌百家(單管)
        118: bacc,
        119: {
            1: "黑桃",
            2: "愛心",
            3: "方塊",
            4: "梅花",
            5: "Joker(皇冠)",
        },
        120: {
            1: "紅方",
            2: "黑方",
            3: "幸運一擊",
            winType: {
                1: "豹子",
                2: "同花順",
                3: "同花",
                4: "順子",
                5: "對子",
                6: "高牌",
            },
            rateRule: {
                1: "紅方",
                2: "黑方",
                3: "對子9以上",
                4: "順子",
                5: "金花",
                6: "順金",
                7: "豹子",
            }
        },
        121: {
            1: "雙",
            2: "單",
            3: "一白",
            4: "一紅",
            5: "全白",
            6: "全紅",
            red: "紅",
            white: "白",
            rateRule: {
                1: "雙",
                2: "單",
                3: "一白",
                4: "一紅",
                5: "全白",
                6: "全紅",
                7: "單/雙Bonus",
                8: "一白/一紅Bonus",
                9: "全白/全紅Bonus",
            }
        },
        122: {
            1: "<i class='icon-tiny fan-tan fan-tan-1'></i> ",
            2: "<i class='icon-tiny fan-tan fan-tan-2'></i> ",
            3: "<i class='icon-tiny fan-tan fan-tan-3'></i> ",
            4: "<i class='icon-tiny fan-tan fan-tan-4'></i> ",
            5: "<i class='icon-tiny fan-tan fan-tan-5'></i> ",
            6: "<i class='icon-tiny fan-tan fan-tan-6'></i> ",
            7: "<i class='icon-tiny fan-tan fan-tan-7'></i> ",
            8: "<i class='icon-tiny fan-tan fan-tan-8'></i> ",
            9: "<i class='icon-tiny fan-tan fan-tan-9'></i> ",
            10: "<i class='icon-tiny fan-tan fan-tan-10'></i> ",
            11: "<i class='icon-tiny fan-tan fan-tan-11'></i> ",
            12: "<i class='icon-tiny fan-tan fan-tan-12'></i> ",
            13: "<i class='icon-tiny fan-tan fan-tan-13'></i> ",
            14: "<i class='icon-tiny fan-tan fan-tan-14'></i> ",
            str: {
                1: "[1]",
                2: "[2]",
                3: "[3]",
                4: "[4]",
                5: "[1、2]",
                6: "[3、1]",
                7: "[4、1]",
                8: "[2、3]",
                9: "[2、4]",
                10: "[3、4]",
                11: "[1、2、3]",
                12: "[1、2、4]",
                13: "[1、3、4]",
                14: "[2、3、4]",
            }
        },
        //視訊百家
        123: bacc,
        124: {
            1: "玩家A",
            2: "玩家B",
            3: "和",
            4: "玩家A對子+",
            5: "玩家B對子+",
            6: "六張牌獎勵",
            winType: {
                1: "六張-同花順AKQJ10",
                2: "六張-同花順",
                3: "六張-鐵支",
                4: "六張-葫蘆",
                5: "六張-同花",
                6: "六張-順子",
                7: "六張-三條",
                8: "六張-高牌",
                9: "三張-三條AAA",
                10: "三張-三條",
                11: "三張-同花順",
                12: "三張-順子",
                13: "三張-同花",
                14: "三張-對子",
                15: "三張-高牌"
            },
            rateRule: {
                1: "玩家A加注",
                2: "玩家B加注",
                3: "和",
                4: "三張-對子",
                5: "三張-同花",
                6: "三張-順子",
                7: "三張-同花順",
                8: "三張-三條",
                9: "三張-三條AAA",
                10: "六張-三條",
                11: "六張-順子",
                12: "六張-同花",
                13: "六張-葫蘆",
                14: "六張-鐵支",
                15: "六張-同花順",
                16: "六張-同花順AKQJ10",
            }
        },
        125: {
            1: "安達",
            2: "巴哈",
            3: "第一手獲勝"
        },
        126: {
            1: "安達",
            2: "巴哈",
            3: "第一手獲勝"
        },
    },
    mj: {
        1: "白板",
        2: "一筒",
        3: "二筒",
        4: "三筒",
        5: "四筒",
        6: "五筒",
        7: "六筒",
        8: "七筒",
        9: "八筒",
        A: "九筒",
    },
    "20002Content": {
        first: "第一重 五福臨門",
        second: "第二重 集字樂",
        third: "第三重 幸運獎",
        1: "第一門",
        2: "第二門",
        3: "第三門",
        4: "第四門",
        5: "第五門",
        collect: "目前累計"
    },
    "10019Content": {
        centerReel: "中間滾輪",
        mari: "小瑪莉"
    }
}
