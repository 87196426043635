export default {
    betRang: "押注范围",
    minBet: "最小押注",
    maxBet: "最大押注",
    updateGameBetRule: "老虎机限红",
    updateGameBetRuleLimit: "更新限制子代理可选限红",
    winLineCount: "总连线数目",
    baseGame: "一般游戏",
    freeGame: "免费游戏",
    get: '获得',
    used: '使用',
    point: '点数',
    total: '总',
    hold: '持有',
    multiplier: "倍率",
    wagerType: {
        0: "一般",
        1: "<span class='text-danger'>殺數</span>",
        2: "<span class='text-warning'>註銷</span>"
    },
    "10017": {
        box: "喜从天降"
    },
    payForFreeGame: "购买免费游戏",
    payForSuperSpin: "购买Super Spin",
    payForDoubleChance: "增加免费游戏机率",
    "10029": {
        dragonBallCollection: "龙珠搜集",
        dragonBallTitle: "龙珠轮盘奖项",
        freeTimes: "免费游戏场次",
        freeMultiplier: "免费累积倍数"
    },
    count: "消除数",
    '10031': {
        awardsTitle: {
            'X': '出局',
            'T': '开宝箱',
            'S': '刮刮乐',
            'W': '轮盘',
            'M': '倍数',
        },
        start: '起点',
        protection: '免死金牌',
        dice: '骰子',
        mapSymbol: '地图格子',
        currentRound: '当前回合',
        specialResult: '特殊游戏结果',
    },
    "10032": {
        battleTitle: "对决结果",
        no: "第",
        round: "回合",
        upgrade: "提升",
        gear: "档次",
        freeEnergy: "免费游戏累积能量",
        gear1: "大拳头鲁夫",
        gear2: "弹簧人鲁夫",
        gear3: "尼卡鲁夫",
        energy: "能量",
    },
    "10033": {
        multiplierUpgrade: "倍数升级"
    },
    "10035" :{
        competition: {
            1:'例行赛（x1）',
            2:'季后赛（x2）',
            4:'冠军赛（x4）',
            6:'亚锦赛（x6）',
            8:'世界赛（x8）',
        },
        total_multi_in_this_round: '此赛局赢得倍数',
        has_back_to_home_plate: '是否有人回本垒',
        is_safe: '是否安全上垒',
        run_base_count: '跑几垒',
        win_multi: '赢得倍率',
        total_multi: '总共赢得倍率',
        case_id:'情境编号',
        final_total_multi: '最终赢得倍率',
    },
}
