<template>
	<b-table
		v-if='gameData'
		striped
		hover
		responsive='xl'
		:items='gameData'
		:fields='getFields()'>

		<template v-slot:cell(slotGUI)='data'>

      <div v-if='data.item.game_state === "BonusGame"'
           style='white-space:nowrap;'

      >
        <div
            class='fade-in'
            v-for='(bonus_detail,index) in gameData[data.index].bonus_game_result.detail_list'
            v-if='gameData[data.index].page === index + 1'
            v-html='getBonusMainDataHtmlStr(bonus_detail)'
        ></div>

        <b-pagination
            v-if='gameData[data.index].bonus_game_result.detail_list.length > 1'
            class='page mt-2'
            size='md'
            :total-rows='gameData[data.index].bonus_game_result.detail_list.length'
            v-model='gameData[data.index].page'
            per-page='1'
            first-number
            last-number
        ></b-pagination>
      </div>

			<div v-if='data.item.game_state === "BaseGame" || data.item.game_state === "FreeGame"'
           style='white-space:nowrap;' v-for='(xItem,x) in getPositionResult(data.index)' :key='x'>
				<i v-for='(id,y) in xItem'
				   :class='getSymbolIcon(data.index,id , x , y)' :key='y'></i>
			</div>

		</template>

		<template v-slot:cell(detail)='data'>
			<p style='white-space:nowrap;' v-html='getSpinTitle(data.item , data.index)'>
			</p>
			<p style='white-space:nowrap;'>
				{{ $t('totalWin') }} : {{ convertMoney(currencyType, data.item.win_point, true) }}
			</p>
			<p  v-if='data.item.game_state === "BaseGame" || data.item.game_state === "FreeGame"' style='white-space:nowrap;'>
				{{ $t('slot.winLineCount') }} : {{ getWinLineCount(data.index) }}
			</p>

			<p style='white-space:nowrap;'>
				{{ $t('slot.multiplier') }} :{{ data.item.multiplier }}
			</p>

      <div v-if='data.item.game_state === "BonusGame"' >
        <p style='white-space:nowrap;'>
          {{ $t('slot.'+gameType+'.final_total_multi') }} : {{ data.item.bonus_game_result.final_total_multi }}
        </p>
      </div>

      <!--   正常的 Show 線    -->
			<b-row class='m-0 p-0 border-top border-dark'
             v-if='data.item.game_state === "BaseGame" || data.item.game_state === "FreeGame"'
             v-html='getOtherHtmlStr(data.index)'>
			</b-row>

      <!--   出現 Bonus 時要 Show 的結果    -->
      <b-row class='m-0 p-0 border-top border-dark bonus-detail'
             v-if='data.item.game_state === "BonusGame"'
             v-html='getOtherHtmlStrForBonus(data.index)'>
      </b-row>

		</template>

	</b-table>
</template>

<script>
import { checkSymbolId } from '@/utils/Slot';

export default {
	name: 'SlotBaseballSlotGoTable',
	props: {
		item: { bet_point: 0 },
		gameData: null,
		gameType: { type: Number, default: null, required: true },
		currencyType: { type: String, default: null, required: true },
	},
  mounted() {
    if(this.item.win_point){
      this.startWinBoxAnimation();
    }
  },
	methods: {
		getFields() {
			return [
				{
					key: 'slotGUI',
					label: '',
					sortable: false,
				},
				{
					key: 'detail',
					label: this.$t('detail'),
					tdClass: 'align-top',
					sortable: false,
				},
			];
		},
		getPositionResult(index) {
      let item = this.gameData[index];
      let positionResult;
      if (item.free_game_other_data && item.free_game_other_data.after_turn_wild_position_result) {
        positionResult = item.free_game_other_data.after_turn_wild_position_result;
      }else{
        positionResult = item.position_result;
      }
			let symbolList = [];

			let length = positionResult.length;
			for (let i = 0; i < length; i++) {
				let length1 = positionResult[i].length;
				for (let j = 0; j < length1; j++) {
					symbolList[j] = symbolList[j] || [];
					symbolList[j][i] = positionResult[i][j];
				}
			}
			return symbolList;
		},
		getSymbolIcon(index, id, x, y) {
			let symbolId = Number(id);
			let item = this.gameData[index];
			let prefix = '';
			let winLines = item.win_lines || [];
			if (winLines.length > 0) {
				let winLine = winLines[this.winLineIndex % item.win_lines.length];
				symbolId = checkSymbolId(this.gameType, item, index, winLine, symbolId, x, y);
				let winPosition = winLine.is_win_position;
				if (winPosition && winPosition[y][x]) {
					prefix = this.winLineBoxShow ? 'winLineBox' : '';
				}
			} else {
				symbolId = checkSymbolId(this.gameType, item, index, null, symbolId, x, y);
			}

			return `icon-small slot-${this.gameType} slot-${this.gameType}-${symbolId}  ${prefix}`;
		},
		getWinLineCount(index) {
			let item = this.gameData[index];
			let winLines = item.win_lines || [];
			return winLines.length;
		},
		getOtherHtmlStr(index) {
			let html = '';

			let item = this.gameData[index];

      let winLines = item.win_lines || [];

      let length = winLines.length;
      let winLineIndex = this.winLineIndex % length;

      for (let i = 0; i < length; i++) {
        let winLine = winLines[i];

        let point = winLine.win_point;
        point = this.convertMoney(this.currencyType, point, true);

        let prefix = '';
        if (winLineIndex === i) {
          prefix = 'winLineBox';
        }

        let lineGameType = [10035];

        if (lineGameType.indexOf(this.gameType) > -1) {
          if (winLine.win_point > 0) {
            html += `<div class='col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2'>`;
            html += `<i class='row mb-1 icon-big slot-line-${this.gameType} slot-line-${this.gameType}-${winLine.line_no + 1} ${prefix}'></i>`;
            if (winLine.line_no > -1) {
              html += `<span class='row text-info' style='white-space:nowrap;'>LinNo : ${winLine.line_no + 1}</span>`;
            }

            html += `<span class='row' style='white-space:nowrap;'>${point}</span>`;
            html += '</div>';
          }
        } else {
          if (prefix.length > 0) {
            prefix = 'border-danger border';
          }

          html += `<span class='mr-2 mt-1 mb-1 p-2 ${prefix}'>${i + 1} : ${point}</span>`;
        }
      }

			return html;
		},
    getBonusMainDataHtmlStr(bonus_detail) {
      let html = '';

      html = `
                <div> ${ this.$t('slot.'+this.gameType+'.competition.'+bonus_detail.competition) }</div>
                <div> ${ this.$t('slot.'+this.gameType+'.total_multi_in_this_round') } : ${bonus_detail.total_multi_in_this_round}</div>
                <div> ${ this.$t('slot.'+this.gameType+'.has_back_to_home_plate') } : ${ this.$t("pk." + (bonus_detail.has_back_to_home_plate == true?"yes":"no")) }</div>
             `;

      return html;
    },
    getOtherHtmlStrForBonus(index) {
      let html = '';

      let item = this.gameData[index];
      let nowIndex = item.page - 1;
      let bonusDetail = item.bonus_game_result.detail_list[nowIndex];
      let eachRoundResultListLen = bonusDetail.each_round_result_list.length;

      for (let i = 0; i < eachRoundResultListLen; i++) {
        let bonusDetailElement = bonusDetail.each_round_result_list[i];
        let board = this.getBonusBoardImgClass("bg");
        // 場上狀態
        let nowBaseState = Number(bonusDetailElement.now_base_state);
        if (Number(nowBaseState & 1) > 0) {
          board += this.getBonusBoardImgClass("1");
        }
        if (Number(nowBaseState & 2) > 0) {
          board += this.getBonusBoardImgClass("2");
        }
        if (Number(nowBaseState & 4) > 0) {
          board += this.getBonusBoardImgClass("4");
        }
        if (Number(nowBaseState & 8) > 0) {
          board += this.getBonusBoardImgClass("8");
        }
        // 好球數
        for (let j = 1; j <= Number(bonusDetailElement.strike_number); j++) {
          board += this.getBonusBoardImgClass(`s${j}`);
        }
        // 出局數
        for (let j = 1; j <= Number(bonusDetailElement.out_number); j++) {
          board += this.getBonusBoardImgClass(`o${j}`);
        }

        html += `<span>
                    <div style="position:relative;">${board}</div>
                    <div> ${ this.$t('slot.'+this.gameType+'.is_safe') } :  ${ this.$t("pk." + (bonusDetailElement.is_safe == true?"yes":"no")) } </div>
                    <div> ${ this.$t('slot.'+this.gameType+'.run_base_count') } : ${bonusDetailElement.run_base_count}</div>
                    <div> ${ this.$t('slot.'+this.gameType+'.win_multi') } : ${bonusDetailElement.win_multi}</div>
                    <div> ${ this.$t('slot.'+this.gameType+'.total_multi') } : ${bonusDetailElement.total_multi}</div>
                 </span>`;

        if (i+1 < eachRoundResultListLen){
          html += `<span class="next_gap"> >> </span>`;
        }
      }

      return html;
    },
    getBonusBoardImgClass(imgName) {
      let bg = `icon-small slot-board-${this.gameType} slot-board-${this.gameType}-${imgName}`;
      let htmlStr = `<i class="${bg}"></i>`;
      return htmlStr;
    },
		getSpinTitle(item, index) {
			let title = '[ ' + item.game_state;
			if (index > 0) {
				title += '<span class="text-success ml-1">' + index + '</span>';
			}
			title += ' ]';
			return title;
		},
		updateFrame(timestamp) {
			if (!this.beforeTimestamp) {
				this.beforeTimestamp = timestamp;
			}

			let dt = timestamp - this.beforeTimestamp;

			if (dt > 300) {
				this.beforeTimestamp = timestamp;
				this.winLineBoxShow = !this.winLineBoxShow;
				this.winLineBoxCount++;
			}

			if (this.winLineBoxCount === 6) {
				this.winLineBoxCount = 0;
				this.winLineIndex++;
			}

			this.frameId = window.requestAnimationFrame(this.updateFrame);
		},
		startWinBoxAnimation() {
			this.frameId = window.requestAnimationFrame(this.updateFrame);
		},
		reset() {
			this.frameId = null;
			this.winLineIndex = 0;
			this.beforeTimestamp = null;
			this.winLineBoxShow = true;
			this.winLineBoxCount = 0;
		},
		close() {
			this.frameId && window.cancelAnimationFrame(this.frameId);
			this.frameId = null;
		},
	},
	data() {
		return {
			frameId: null,
			beforeTimestamp: null,
			winLineIndex: 0,
			winLineBoxShow: true,
			winLineBoxCount: 0,
		};
	},
	destroyed() {
		this.close();
	},
};
</script>

<style scoped>
  .bonus-detail >>> .next_gap {
    width: 60px;
    margin-bottom: auto;
    margin-top: auto;
    text-align: center;
    display: inline-block;
  }
</style>
