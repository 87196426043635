export default {
    betRang: "押注範圍",
    minBet: "最小押注",
    maxBet: "最大押注",
    updateGameBetRule: "老虎機限紅",
    updateGameBetRuleLimit: "更新限制子代理可選限紅",
    winLineCount: "總連線數目",
    baseGame: "一般遊戲",
    freeGame: "免費遊戲",
    get: '獲得',
    used: '使用',
    point: '點數',
    total: '總',
    hold: '持有',
    multiplier: "倍率",
    wagerType: {
        0: "一般",
        1: "<span class='text-danger'>殺數</span>",
        2: "<span class='text-warning'>註銷</span>"
    },
    "10017": {
        box: "喜從天降"
    },
    payForFreeGame: "購買免費遊戲",
    payForSuperSpin: "購買Super Spin",
    payForDoubleChance: "增加免費遊戲機率",
    "10029": {
        dragonBallCollection: "龍珠蒐集",
        dragonBallTitle: "龍珠輪盤獎項",
        freeTimes: "免費遊戲場次",
        freeMultiplier: "免費累積倍數"
    },
    count: "消除數",
    '10031': {
        awardsTitle: {
            'X': '出局',
            'T': '開寶箱',
            'S': '刮刮樂',
            'W': '輪盤',
            'M': '倍數',
        },
        start: '起點',
        protection: '免死金牌',
        dice: '骰子',
        mapSymbol: '地圖格子',
        currentRound: '當前回合',
        specialResult: '特殊遊戲結果',
    },
    "10032": {
        battleTitle: "對決結果",
        no: "第",
        round: "回合",
        upgrade: "提升",
        gear: "檔次",
        freeEnergy: "免費遊戲累積能量",
        gear1: "大拳頭魯夫",
        gear2: "彈簧人魯夫",
        gear3: "尼卡魯夫",
        energy: "能量",
    },
    "10033": {
        multiplierUpgrade: "倍數升級"
    },
    '10035' :{
        competition: {
            1:'例行賽（x1）',
            2:'季後賽（x2）',
            4:'冠軍賽（x4）',
            6:'亞錦賽（x6）',
            8:'世界賽（x8）',
        },
        total_multi_in_this_round: '此賽局贏得倍數',
        has_back_to_home_plate: '是否有人回本壘',
        is_safe: '是否安全上壘',
        run_base_count: '跑幾壘',
        win_multi: '贏得倍率',
        total_multi: '總共贏得倍率',
        case_id:'情境編號',
        final_total_multi: '最終贏得倍率',
    },
}
